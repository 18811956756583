import { createRoot } from 'react-dom/client';
import { App } from './App';
import { configureUserAuth, Configuration } from '@mcrp/web-api';
import { version as ApiVersion } from '@marketcast/rpapi';
import { Provider } from 'react-redux';
import { store } from './store';

Configuration.setConfiguration(API_HOST, {
  application: PACKAGE_VERSION,
  protocol: ApiVersion,
});

configureUserAuth(
  API_AMPLIFY.userPoolId,
  API_AMPLIFY.clientId,
  API_AMPLIFY.domain
);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
