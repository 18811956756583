import { combineReducers } from 'redux';
import type { InitState } from './init';
import { InitReducer } from './init';

export default combineReducers({
  init: InitReducer,
});

export type AppState = {
  init: InitState;
};
