import type { FunctionComponent, PropsWithChildren } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { getUserSelector } from '@mcrp/web-api';

export const RequireAuth: FunctionComponent<PropsWithChildren> = () => {
  const user = useAppSelector(getUserSelector);
  const location = useLocation();

  if (!user) {
    return <Navigate to={'/login'} state={{ from: location }} replace={true} />;
  }

  return <Outlet />;
};
