import { ChakraProvider } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { FontDeclarations, theme } from '@marketcast/ui-kit';
import { Routing } from './Routing';
import { HelmetProvider } from 'react-helmet-async';
import { useAppDispatch } from './hooks';
import { initialize } from './app/actions';

export const App: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    void dispatch(initialize());
  }, [dispatch]);

  return (
    <HelmetProvider>
      <ChakraProvider theme={theme}>
        <FontDeclarations />
        <Routing />
      </ChakraProvider>
    </HelmetProvider>
  );
};
