import { createSelector } from '@reduxjs/toolkit';
import type { InitState } from '../reducers/init';
import type { RootState } from '../../store';

const getBaseState = (state: RootState): InitState => state.app.init;

export const isInitialized = createSelector(
  [getBaseState],
  (state: InitState): boolean => {
    return state.initialized;
  }
);
