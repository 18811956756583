import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducers';
import { api } from '@mcrp/web-api';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
