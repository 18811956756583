import type { FunctionComponent } from 'react';
import {
  Page,
  PageBanner,
  PageBody,
  PageFooter,
  PageHeader,
  PageLoader,
} from '@marketcast/ui-kit';

export const LoadingPage: FunctionComponent = () => {
  return (
    <Page>
      <PageHeader />
      <PageBanner />
      <PageBody>
        <PageLoader />
      </PageBody>
      <PageFooter />
    </Page>
  );
};
