import { Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { RequireAuth } from '../common/components/routes';

export const Routes: RouteObject[] = [
  {
    path: '/metadata',
    element: <RequireAuth />,
    children: [
      {
        path: '/metadata/*',
        lazy: async () => {
          const { MetadataPage } = await import('./components/pages/base');
          return { Component: MetadataPage };
        },
        children: [
          {
            index: true,
            element: <Navigate replace={true} to={'company'} />,
          },
          {
            path: 'company',
            lazy: async () => {
              const { CompanyContent } = await import(
                './components/pages/base'
              );
              return { Component: CompanyContent };
            },
          },
          {
            path: 'brand',
            lazy: async () => {
              const { BrandContent } = await import('./components/pages/base');
              return { Component: BrandContent };
            },
          },
          {
            path: 'product',
            lazy: async () => {
              const { ProductContent } = await import(
                './components/pages/base'
              );
              return { Component: ProductContent };
            },
          },
          {
            path: 'category',
            lazy: async () => {
              const { CategoryContent } = await import(
                './components/pages/base'
              );
              return { Component: CategoryContent };
            },
          },
        ],
      },
    ],
  },
];
