import { createReducer } from '@reduxjs/toolkit';

import { initialize } from '../actions';

export type InitState = {
  initialized: boolean;
};

const initialState: InitState = {
  initialized: false,
};

export const InitReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(initialize.rejected, (state) => {
      state.initialized = true;
    })
    .addCase(initialize.fulfilled, (state) => {
      state.initialized = true;
    });
});
