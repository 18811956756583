import type { FunctionComponent } from 'react';
import { Suspense, memo } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useAppSelector } from './hooks';
import { isInitialized } from './app/selectors';
import { LoadingPage } from './app/components/pages/loading';
import { Routes as AppRoutes } from './app/Routes';
import { Routes as AdsRoutes } from './ads/Routes';
import { Routes as MetadataRoutes } from './metadata/Routes';
import { AppLayout } from './AppLayout';
import type { RouteObject } from 'react-router-dom';

export const Routes: RouteObject[] = [
  {
    path: '/',
    element: <AppLayout />,
    children: [...AppRoutes, ...AdsRoutes, ...MetadataRoutes],
  },
];

export const RoutingBase: FunctionComponent = () => {
  const initialized = useAppSelector(isInitialized);

  const router = createBrowserRouter(Routes);

  return initialized ? (
    <Suspense fallback={<LoadingPage />}>
      <RouterProvider router={router} />
    </Suspense>
  ) : (
    <LoadingPage />
  );
};

export const Routing = memo(RoutingBase);
