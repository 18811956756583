import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

// istanbul ignore next No point in testing lazy imports
const LoginPage = lazy(() => import('./components/pages/login'));

export const Routes = [
  { index: true, element: <Navigate replace={true} to={'/ads'} /> },
  {
    path: '/login',
    element: <LoginPage />,
  },
];
