import type { FunctionComponent } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUserAuthRedirect } from '@mcrp/web-api';

export const AppLayout: FunctionComponent = () => {
  const navigate = useNavigate();
  useUserAuthRedirect({ onNavigate: navigate });

  return <Outlet />;
};
