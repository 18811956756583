import { RequireAuth } from '../common/components/routes';
import { lazy } from 'react';

// istanbul ignore next No point in testing lazy imports
const AdsListPage = lazy(() => import('./components/pages/adslist'));

export const Routes = [
  {
    path: '/ads',
    element: <RequireAuth />,
    children: [{ index: true, element: <AdsListPage /> }],
  },
];
